import React, { StrictMode } from 'react'
import './assets/styles/index.scss'
import ReactDOM from 'react-dom'
import App from './app/App'
import * as serviceWorker from './serviceWorker'
import { subscribeUser } from './services/subscription.service'
import { authenticationService } from './services/auth.service'

// import serialize from 'serialize-javascript'
// console.log(serialize(window['SERVER_DATA'])) // Using server data after serializing

ReactDOM.render(
    <StrictMode>
        <App />
    </StrictMode>,
    document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register()

if (
    authenticationService.currentUserValue &&
    authenticationService.currentUserValue.enablePushNotifications
) {
    subscribeUser()
}
