import React from 'react'
import './SearchInput.scss'
import { FormControlProps } from 'react-bootstrap'
import { Input } from '../text-input'

export type SearchInputProps = FormControlProps & any

export const SearchInput = (props: SearchInputProps) => {
    let { className, type, name, innerRef, isInvalid, placeholder, ...rest } = props

    return (
        <div className="search-input-container">
            <div className="search">
                <Input
                    type="text"
                    name={name}
                    placeholder={placeholder}
                    innerRef={innerRef}
                    isInvalid={isInvalid}
                    className={className}
                    {...rest}
                />
                <span>
                    <i className="bx bx-fw bx-search"></i>
                </span>
            </div>
        </div>
    )
}

SearchInput.defaultProps = {
    type: 'text',
    name: 'myInput',
    placeholder: '',
    className: '',
}
