import React from 'react'
import { Redirect, Route, Router, Switch } from 'react-router-dom'
import { components } from '../../routes/routes.config'
import history from '../../routes/history'

export default function FlowWizardLayout(props) {
    return (
        <Router history={history}>
            <Switch>
                <Redirect
                    key="default"
                    exact
                    from="/flow/"
                    to="/flow/choose-template"
                ></Redirect>
                <Route exact path="/flow/:flowId/*" key="app-wizard">
                    <components.FlowWizardNavigator />
                </Route>
            </Switch>
        </Router>
    )
}
