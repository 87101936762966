import React, { useState } from 'react'
import './Drawer.scss'

import { useRef, useEffect } from 'react'
import { createPortal } from 'react-dom'
import FocusTrap from 'focus-trap-react'
import cn from 'classnames'

export type DrawerProps = {
    /**
     * Is open
     */
    isOpen?: boolean
    /**
     * Is fullscreen
     */
    isFullscreen?: boolean
    /**
     * Class name
     */
    className?: string
    /**
     * On close
     */
    onClose?: any
    /**
     * Position
     */
    position?: string
    /**
     * Remove when closed
     */
    removeWhenClosed?: boolean
    /**
     * Children
     */
    children?: any
    /**
     * Width
     */
    width?: string
    /**
     * Backdrop
     */
    backdrop?: 'static' | 'dynamic'
}

function createPortalRoot() {
    const drawerRoot = document.createElement('div')
    drawerRoot.setAttribute('id', 'drawer-root')
    return drawerRoot
}

const useMountTransition = (isMounted, unmountDelay) => {
    const [isTransitioning, setIsTransitioning] = useState(false)

    useEffect(() => {
        let timeoutId

        if (isMounted && !isTransitioning) {
            setIsTransitioning(true)
        } else if (!isMounted && isTransitioning) {
            timeoutId = setTimeout(() => setIsTransitioning(false), unmountDelay)
        }
        return () => {
            clearTimeout(timeoutId)
        }
    }, [unmountDelay, isMounted, isTransitioning])

    return isTransitioning
}

/*
 * Read the blog post here:
 * https://letsbuildui.dev/articles/building-a-drawer-component-with-react-portals
 */
export const Drawer = ({
    isOpen,
    children,
    className,
    onClose,
    position = 'left',
    removeWhenClosed = true,
    isFullscreen,
    width,
    backdrop,
}: DrawerProps) => {
    const bodyRef = useRef(document.querySelector('body')) as any
    const portalRootRef = useRef(
        document.getElementById('drawer-root') || createPortalRoot()
    )
    const isTransitioning = useMountTransition(isOpen, 300)

    // Append portal root on mount
    useEffect(() => {
        bodyRef.current.appendChild(portalRootRef.current)
        const portal = portalRootRef.current
        const bodyEl = bodyRef.current

        return () => {
            // Clean up the portal when drawer component unmounts
            portal.remove()
            // Ensure scroll overflow is removed
            bodyEl.style.overflow = ''
        }
    }, [])

    // Prevent page scrolling when the drawer is open
    useEffect(() => {
        const updatePageScroll = () => {
            if (isOpen) {
                bodyRef.current.style.overflow = 'hidden'
            } else {
                bodyRef.current.style.overflow = ''
            }
        }

        updatePageScroll()
    }, [isOpen])

    // Allow Escape key to dismiss the drawer
    useEffect(() => {
        const onKeyPress = (e) => {
            if (e.key === 'Escape' && backdrop != 'static') {
                onClose()
            }
        }

        if (isOpen) {
            window.addEventListener('keyup', onKeyPress)
        }

        return () => {
            window.removeEventListener('keyup', onKeyPress)
        }
    }, [isOpen, onClose])

    if (!isTransitioning && removeWhenClosed && !isOpen) {
        return null
    }

    return createPortal(
        <FocusTrap active={isOpen}>
            <div
                aria-hidden={isOpen ? 'false' : 'true'}
                className={cn('drawer-container', {
                    open: isOpen,
                    in: isTransitioning,
                    className,
                })}
            >
                <div
                    className={cn(
                        'drawer',
                        position +
                            (isFullscreen ? ' drawer-fullscreen' : '') +
                            (className?.includes('dark-bg') ? ' dark-bg' : '')
                    )}
                    style={{ width: width || '30%' }}
                    role="dialog"
                >
                    {children}
                </div>
                <div
                    className="backdrop"
                    onClick={() => {
                        if (backdrop != 'static') onClose()
                    }}
                />
            </div>
        </FocusTrap>,
        portalRootRef.current
    )
}

Drawer.defaultProps = {
    isOpen: false,
    position: 'left',
    removeWhenClosed: true,
    isFullscreen: false,
    backdrop: 'dynamic'
}
