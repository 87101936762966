import React from 'react'
import './FlowCard.scss'

export type FlowCardProps = {
    /**
     * Title
     */
    title?: string
    /**
     * Description
     */
    description?: string
    /**
     * Background class name
     */
    backgroundClassName?: string
    /**
     * On click
     */
     onClick?: any
}

export const FlowCard = ({
    title,
    description,
    backgroundClassName,
    onClick,
    ...rest
}: FlowCardProps) => {
    return (
        <div className="col px-2 mb-3" onClick={onClick}>
            <div className="card h-100 flow-card border-0">
                <div className="card-header border-0 p-0">
                    <a
                        href="#!"
                        className={`flow-card-header-btn p-4 text-left rounded-top ${backgroundClassName}`}
                    >
                        <i className="bx bxs-layer bx-md"></i>
                    </a>
                </div>
                <div className="card-body border rounded-bottom border-top-0">
                    <div className="d-flex">
                        <div className="flex-grow-1">
                            <h5 className="card-title mb-0">
                                <a href="#!" className="flow-card-link">
                                    {title}
                                </a>
                            </h5>
                            <a href="#!" className="flow-card-link">
                                <small>{description}</small>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

FlowCard.defaultProps = {
    title: 'Default title',
    description: 'Default description.',
    backgroundClassName: 'blue-pattern',
}
