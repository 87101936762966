import React from 'react'
import './SelectDropdown.scss'
import Select, { components, ControlProps, StylesConfig } from 'react-select'
import Avatar from 'react-avatar'
import { Table } from 'react-bootstrap'
import { DatasetRowRenderer } from '../../form-builder/dataset-row-renderer'
import CreatableSelect from 'react-select/creatable'

export type SelectDropdownProps = ControlProps & any

export const SelectDropdown = (props: SelectDropdownProps) => {
    let {
        className,
        name,
        innerRef,
        placeholder,
        children,
        CustomOptionComponent,
        options,
        menuWidth,
        disabled,
        allowCreate,
        ...rest
    } = props

    const styles: StylesConfig<any, true> = {
        multiValue: (base, state) => {
            return state.data.isFixed ? { ...base, backgroundColor: 'gray' } : base
        },
        multiValueLabel: (base, state) => {
            return state.data.isFixed
                ? { ...base, fontWeight: 'bold', color: 'white', paddingRight: 6 }
                : base
        },
        multiValueRemove: (base, state) => {
            return state.data.isFixed ? { ...base, display: 'none' } : base
        },
    }

    return (
        <>
            {allowCreate && (
                <CreatableSelect
                    placeholder={placeholder}
                    components={{ Option: CustomOptionComponent }}
                    options={options}
                    // menuPortalTarget={document.body}
                    // menuPosition="fixed"
                    isDisabled={disabled}
                    {...rest}
                    styles={{
                        ...styles,
                        menuPortal: (provided) => ({
                            ...provided,
                            width: menuWidth,
                        }),
                    }}
                />
            )}
            {!allowCreate && (
                <Select
                    placeholder={placeholder}
                    components={{ Option: CustomOptionComponent }}
                    options={options}
                    // menuPortalTarget={document.body}
                    // menuPosition="fixed"
                    isDisabled={disabled}
                    {...rest}
                    styles={{
                        ...styles,
                        menuPortal: (provided) => ({
                            ...provided,
                            width: menuWidth,
                        }),
                    }}
                />
            )}
        </>
    )
}

// Common user option component
export const UserOptionComponent = ({ children, ...props }) => {
    return (
        <components.Option {...(props as any)}>
            <div className="d-flex align-items-center">
                <div className="avatar flex-shrink-0">
                    <Avatar
                        name={props.label}
                        size="38"
                        round={true}
                        src={props.data.avatar}
                    />
                </div>
                <div className="flex-grow-1 pl-2 pr-0">
                    <div className="fw-medium user-name">{props.label}</div>
                    <div className="text-truncate email-wrapper">
                        <small>{props.data.email}</small>
                    </div>
                </div>
            </div>
        </components.Option>
    )
}

// Dataset row option component
export const DatasetRowOptionComponent = ({ children, ...props }) => {
    return (
        <components.Option {...(props as any)}>
            <strong>{props.data['key']}</strong>
            <Table className="mb-0 mt-2 pb-0">
                <tr>
                    {props.data.columns.map((column) => (
                        <>
                            {column !== 'key' && (
                                <th className="capitalize">{column}</th>
                            )}
                        </>
                    ))}
                </tr>
                <tbody>
                    <tr>
                        {props.data.columns.map((column: any) => (
                            <>
                                {column !== 'key' && (
                                    <td>
                                        <DatasetRowRenderer
                                            row={props.data}
                                            column={{ key: column }}
                                        />
                                    </td>
                                )}
                            </>
                        ))}
                    </tr>
                </tbody>
            </Table>
        </components.Option>
    )
}

SelectDropdown.defaultProps = {
    name: 'mySelectDropdown',
    placeholder: 'Type to search',
    className: '',
    CustomOptionComponent: ({ children, ...props }) => {
        return <components.Option {...(props as any)}> {children}</components.Option>
    },
    options: [],
    menuWidth: '270px',
    allowCreate: false
}
