import React, { useEffect, useState } from 'react'
import { Sidebar } from '../../components/layout/sidebar/Sidebar'
import { Router, Switch } from 'react-router-dom'
import { routes } from '../../routes/routes.config'
import { Footer } from '../../components/layout/footer/Footer'
import history from '../../routes/history'
import Routes from '../../routes/routes'
import { SubSidebar } from '../../components/layout/sub-sidebar'
import { useLocation } from 'react-router-dom'
import { sidebarRoutes } from '../../routes/sidebar.routes.config'
import { authenticationService } from '../../services/auth.service'
import { computePathForSubSidebar } from '../../services/general.service'
import { flowService } from '../../services/flow.service'

export default function AppLayout(props) {
    const location = useLocation()
    const [user, setUser] = useState({} as any)
    const [doingLogout, setDoingLogout] = useState(false)
    const [subSidebarRouteConfig, setSubSidebarRouteConfig] = useState(
        computePathForSubSidebar(location.pathname)
    )

    useEffect(() => {
        let mounted = true
        authenticationService.currentUser.subscribe((user) => {
            mounted && setUser(user)
        })
        let flowsSubscription: any

        if (!subSidebarRouteConfig.hide && !subSidebarRouteConfig.menus) {
            subSidebarRouteConfig.showLoader = true
            setSubSidebarRouteConfig({ ...subSidebarRouteConfig })
            flowService.fetchFlows(true)
            flowsSubscription = flowService.flowsSubject.subscribe((flows: any) => {
                subSidebarRouteConfig.menus = !flows.showLoader
                    ? flows
                          ?.map((e) => {
                              return {
                                  displayText:
                                      `${e.name}` +
                                      (e.tasksCount ? ` (${e.tasksCount})` : ``),
                                  id: e._id,
                                  url: `/inbox/filtered/${e.key}`,
                              }
                          })
                          .concat([
                              {
                                  displayText: `See all`,
                                  id: '',
                                  url: `/flows`,
                                  icon: 'bx bx-right-arrow-alt bx-fw',
                              },
                          ])
                    : []
                subSidebarRouteConfig.showLoader = !flows
                setSubSidebarRouteConfig({ ...subSidebarRouteConfig })
            })
        }
        return () => {
            mounted = false
            if (flowsSubscription) flowsSubscription.unsubscribe()
        }
    }, [])

    return (
        <>
            <div className="sidebar-menu vh-100 d-flex">
                <Sidebar
                    logo={sidebarRoutes.mainApp.logo}
                    menus={sidebarRoutes.mainApp.menus as any}
                    name="99Flows"
                    doingLogout={doingLogout}
                    onLogout={(e) => {
                        setDoingLogout(true)
                        authenticationService.logout().then((response) => {
                            setDoingLogout(false)
                        })
                    }}
                    userProfile={{
                        avatar: (user && user.avatar) || '',
                        options: sidebarRoutes.mainApp.userProfile.options as any,
                        name: `${user?.firstName} ${user?.lastName}`,
                    }}
                ></Sidebar>
                <SubSidebar
                    heading={subSidebarRouteConfig.heading}
                    menus={subSidebarRouteConfig.menus}
                    hide={subSidebarRouteConfig.hide}
                    showCreateFlowButton={subSidebarRouteConfig.showCreateFlowButton}
                    showSearchBox={subSidebarRouteConfig.showSearchBox}
                    showLoader={subSidebarRouteConfig.showLoader}
                />
            </div>
            <Router history={history}>
                <Switch>
                    {routes.map((item) => {
                        return (
                            <Routes
                                exact
                                component={item.component}
                                key={item.path}
                                path={item.path}
                            />
                        )
                    })}
                </Switch>
            </Router>
            {/* <Footer /> */}
        </>
    )
}
