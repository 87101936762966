export const subSidebarRoutes = {
    '/settings/general': {
        hide: false,
        heading: 'Settings',
        menus: [
            {
                displayText: 'General',
                id: 'general',
                url: '/settings/general',
            },
            {
                displayText: 'Users',
                id: 'users',
                url: '/settings/users',
            },
            {
                displayText: 'Teams',
                id: 'teams',
                url: '/settings/teams',
            },
        ],
    },
    '/profile': {
        hide: true,
    },
    '/inbox': {
        hide: false,
        heading: 'Flows',
        showCreateFlowButton: true,
        showSearchBox: true,
        showLoader: false,
    },
    '/inbox/:processId': {
        hide: false,
        heading: 'Flows',
        showCreateFlowButton: true,
        showSearchBox: true,
        showLoader: false,
    },
    '/dashboard/my-tasks': {
        hide: false,
        heading: 'Flows',
        showCreateFlowButton: true,
        showSearchBox: true,
        showLoader: false,
    },
    '/dashboard/my-requests': {
        hide: false,
        heading: 'Flows',
        showCreateFlowButton: true,
        showSearchBox: true,
        showLoader: false,
    },
    '/dashboard/all-requests': {
        hide: false,
        heading: 'Flows',
        showCreateFlowButton: true,
        showSearchBox: true,
        showLoader: false,
    },
    '/dashboard/insights': {
        hide: false,
        heading: 'Flows',
        showCreateFlowButton: true,
        showSearchBox: true,
        showLoader: false,
    },
    '/dashboard/reports': {
        hide: false,
        heading: 'Flows',
        showCreateFlowButton: true,
        showSearchBox: true,
        showLoader: false,
    },
    '/dashboard/reports/:reportId': {
        hide: false,
        heading: 'Flows',
        showCreateFlowButton: true,
        showSearchBox: true,
        showLoader: false,
    },
    '/dashboard/dashboards': {
        hide: false,
        heading: 'Flows',
        showCreateFlowButton: true,
        showSearchBox: true,
        showLoader: false,
    },
    '/dashboard/dashboards/:dashboardId': {
        hide: false,
        heading: 'Flows',
        showCreateFlowButton: true,
        showSearchBox: true,
        showLoader: false,
    },
    '/dashboard/settings': {
        hide: false,
        heading: 'Flows',
        showCreateFlowButton: true,
        showSearchBox: true,
        showLoader: false,
    },
    '/flows': {
        hide: true,
    },
    '/datasets': {
        hide: true,
    },
    '/datasets/:datasetId': {
        hide: true,
    },
    '/automations': {
        hide: true,
    },
    '/automation/:automationId': {
        hide: true,
    },
    // Add your sub sidebar route here
}

subSidebarRoutes['/settings/users'] = subSidebarRoutes['/settings/general']
subSidebarRoutes['/settings/teams'] = subSidebarRoutes['/settings/general']
