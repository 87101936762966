import React, { useState } from 'react'
import { Dropdown } from 'react-bootstrap'
import history from '../../../routes/history'
import { randomCode } from '../../../services/general.service'
import { Loader } from '../../loader'
import { Link } from '../../inputs/link'
import Avatar from 'react-avatar'
import './Sidebar.scss'
import { Drawer } from '../../drawer'
import { InitiateRequest } from '../../../pages/initiate-request/initiate-request'
import { Notifications } from '../../../pages/notifications/notifications'
import { SearchEverything } from '../../../pages/search-everything/search-everything'

// The forwardRef is important!!
// Dropdown needs access to the DOM node in order to position the Menu
const CustomToggle = React.forwardRef(({ children, onClick }: any, ref: any) => (
    <a
        href="#!"
        className="avatar m-auto"
        ref={ref}
        onClick={(e) => {
            e.preventDefault()
            onClick(e)
        }}
    >
        {children}
    </a>
))

// forwardRef again here!
// Dropdown needs access to the DOM of the Menu to measure it
const CustomMenu = React.forwardRef(
    (
        { children, style, className, 'aria-labelledby': labeledBy }: any,
        ref: any
    ) => {
        const [value, setValue] = useState('')

        return (
            <div
                ref={ref}
                style={style}
                className={className}
                aria-labelledby={labeledBy}
            >
                <ul className="list-unstyled">
                    {React.Children.toArray(children).filter(
                        (child: any) =>
                            !value ||
                            child.props.children.toLowerCase().startsWith(value)
                    )}
                </ul>
            </div>
        )
    }
)

export type SidebarProps = {
    /**
     * Logo image
     */
    logo?: string
    /**
     * Name for alt text
     */
    name?: string
    /**
     * Menus list
     */
    menus: [IMenu]
    /**
     * User Profile
     */
    userProfile: {
        avatar: string
        options: [IMenu]
        name: string
    }
    /**
     * On logout event will be emitted
     */
    onLogout?: any
    /**
     * Doing logout
     */
    doingLogout?: boolean
    /**
     * Hide sidebar
     */
    hide?: boolean
}

export const Sidebar = ({
    logo,
    name = '99Flows',
    menus,
    userProfile,
    onLogout,
    doingLogout,
    hide,
    ...rest
}: SidebarProps) => {
    const [isInitRequestDrawerOpen, setInitRequestDrawerOpen] = useState(false)
    const [isSearchEverythingDrawerOpen, setSearchEverythingDrawerOpen] = useState(
        false
    )
    const [isNotificationsDrawerOpen, setNotificationsDrawerOpen] = useState(false)

    return (
        <>
            <div
                className={
                    hide
                        ? 'd-none'
                        : 'main-menu bg-dark vh-100 text-center text-white flex-shrink-0'
                }
            >
                <a
                    href="#!"
                    onClick={(e) => {
                        e.preventDefault()
                        history.push('/')
                    }}
                    className="logo d-block py-3"
                >
                    <img src={logo} alt={name} width="34" />
                </a>

                <ul>
                    {menus.map((menu) => {
                        return (
                            <li key={randomCode()}>
                                <Link className="main-menu-link" to={menu.url}>
                                    <i
                                        className={menu.icon}
                                        data-toggle="tooltip"
                                        data-placement="right"
                                        title={menu.displayText}
                                    ></i>
                                </Link>
                            </li>
                        )
                    })}
                    <li>
                        <a
                            href="#!"
                            className="main-menu-link bg-brand mt-3"
                            onClick={(e) => {
                                e.preventDefault()
                                setInitRequestDrawerOpen(true)
                            }}
                        >
                            <i
                                className="bx bx-plus text-white"
                                data-toggle="tooltip"
                                data-placement="right"
                                title="Initiate a request"
                            ></i>
                        </a>
                    </li>
                </ul>

                <ul className="main-menu-bottom-nav">
                    <li>
                        <a
                            href="#!"
                            className="main-menu-link"
                            onClick={(e) => {
                                e.preventDefault()
                                setSearchEverythingDrawerOpen(true)
                            }}
                        >
                            <i
                                className="bx bx-search"
                                data-toggle="tooltip"
                                data-placement="right"
                                title="Search everything"
                            ></i>
                        </a>
                    </li>
                    <li>
                        <a
                            href="#!"
                            onClick={(e) => {
                                e.preventDefault()
                                setNotificationsDrawerOpen(true)
                            }}
                            className="main-menu-link"
                        >
                            <i
                                className="bx bx-bell"
                                data-toggle="tooltip"
                                data-placement="right"
                                title="Notifications"
                            ></i>
                        </a>
                    </li>
                    <li className="py-2">
                        {doingLogout ? (
                            <Loader />
                        ) : (
                            <Dropdown>
                                <Dropdown.Toggle
                                    as={CustomToggle}
                                    id="dropdown-custom-components"
                                >
                                    <Avatar
                                        name={userProfile.name}
                                        size="38"
                                        round={true}
                                        src={userProfile.avatar}
                                    />
                                </Dropdown.Toggle>

                                <Dropdown.Menu as={CustomMenu}>
                                    {userProfile.options.map((option) => {
                                        return (
                                            <Dropdown.Item
                                                onClick={(e) => {
                                                    history.push(option.url)
                                                }}
                                                key={randomCode()}
                                                eventKey={option.id}
                                            >
                                                <i className={option.icon}></i>{' '}
                                                {option.displayText}
                                            </Dropdown.Item>
                                        )
                                    })}
                                    <div className="dropdown-divider"></div>

                                    <Dropdown.Item
                                        onClick={onLogout}
                                        eventKey="logout"
                                    >
                                        <i className="bx bx-log-in bx-fw"></i>Logout
                                    </Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        )}
                    </li>
                </ul>
            </div>

            {/* Initiate Request Drawer */}
            <Drawer
                isOpen={isInitRequestDrawerOpen}
                position="left"
                isFullscreen={true}
                className="dark-bg"
                onClose={() => setInitRequestDrawerOpen(false)}
            >
                <InitiateRequest onClose={() => setInitRequestDrawerOpen(false)} />
            </Drawer>

            {/* Notifications Drawer */}
            <Drawer
                isOpen={isNotificationsDrawerOpen}
                position="left"
                isFullscreen={false}
                width="44%"
                onClose={() => setNotificationsDrawerOpen(false)}
            >
                <Notifications onClose={() => setNotificationsDrawerOpen(false)} />
            </Drawer>

            {/* Search Everything Drawer */}
            <Drawer
                isOpen={isSearchEverythingDrawerOpen}
                position="left"
                isFullscreen={true}
                className="dark-bg"
                onClose={() => setSearchEverythingDrawerOpen(false)}
            >
                <SearchEverything
                    onClose={() => setSearchEverythingDrawerOpen(false)}
                />
            </Drawer>
        </>
    )
}

// Menu interface type
export interface IMenu {
    id: string // For rendering optimization
    url: string // Relative URL
    displayText?: string // Text to be displayed
    icon?: string // Icon to be displayed
}

Sidebar.defaultProps = {
    logo: '/images/99flows-logo.png',
    name: '99Flows',
    menus: [
        {
            id: '1',
            url: '/menu-1',
            displayText: 'Menu 1',
            icon: 'bx bxs-inbox',
        },
        {
            id: '2',
            url: '/menu-2',
            displayText: 'Menu 2',
            icon: 'bx bxs-package',
        },
        {
            id: 'n',
            url: '/menu-n',
            displayText: 'Menu n',
            icon: 'bx bx-data',
        },
    ],
    userProfile: {
        avatar: '/images/default-profile-pic.png',
        options: [
            {
                displayText: 'My profile',
                id: 'my-profile',
                url: '/profile',
                icon: `bx bx-user bx-fw`,
            },
            {
                displayText: 'Settings',
                id: 'settings/general',
                url: '/',
                icon: `bx bx-cog bx-fw`,
            },
            {
                displayText: `What's new`,
                id: 'what-new',
                url: '/',
                icon: `bx bxs-megaphone bx-fw`,
            },
            {
                displayText: 'Contact support',
                id: 'support',
                url: '/',
                icon: `bx bx-support bx-fw`,
            },
        ],
    },
}
