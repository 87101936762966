import React from 'react'
import { Loader } from '../../loader'
import './Button.scss'

export type ButtonProps = {
    /**
     * Type of button [submit/button].
     */
    type: 'submit' | 'button'
    /**
     * Is Button disabled?
     */
    disabled?: false | true
    /**
     * Class name
     */
    className: string
    /**
     * Is button processing?
     */
    isLoading?: false | true
    /**
     * Text
     */
    text?: string
} & React.ButtonHTMLAttributes<HTMLButtonElement>

export const Button = ({
    text = 'Submit',
    disabled,
    className = 'btn btn-primary',
    isLoading,
    type = 'submit',
    children,
    ...rest
}: ButtonProps) => {
    return (
        <button
            className={className + ' btn'}
            type={type}
            disabled={isLoading || disabled}
            {...rest}
        >
            {isLoading ? <Loader type="dots" /> : children || text}
        </button>
    )
}

Button.defaultProps = {
    text: 'Submit',
    className: 'btn btn-primary',
    isLoading: false,
    type: 'submit',
}
