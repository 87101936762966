import React, { useState } from 'react'
import './DatasetRowRenderer.scss'
import Moment from 'react-moment'
import { FieldTypes } from '../../../services/interfaces/field'
import { isDate, omit } from 'lodash'
import { toArray } from '../../../services/general.service'

export type DatasetRowRendererProps = {
    /**
     * Row
     */
    row: any
    /**
     * Column
     */
    column: any
}

const determineFieldType = (row, column) => {
    let fieldType: any
    if (!row[column?.key]) {
        fieldType = FieldTypes.ShortText
        return fieldType
    }

    if (row[column?.key]['countryCode']) fieldType = FieldTypes.Phone
    else if (isDate(row[column?.key])) fieldType = FieldTypes.Date
    else if (row[column?.key] && row[column?.key][0] && row[column?.key][0].file)
        fieldType = FieldTypes.FileUpload
    else fieldType = FieldTypes.ShortText

    return fieldType.toString()
}

const parseFile = (file) => {
    let parsedFile: any
    try {
        parsedFile = JSON.parse(file.file)
    } catch (e) {
        parsedFile = file.file
    }
    return parsedFile
}

const LookupTableData = ({ dataObject }) => {
    const dataArray =
        toArray(
            omit(dataObject, [
                'createdAt',
                'datasetArchitecture',
                'updatedAt',
                '__v',
                '_id',
                '_org',
            ])
        ) || []
    return (
        <>
            <table>
                <tbody>
                    {dataObject &&
                        dataArray.map((r) => (
                            <tr>
                                <td className='py-1' style={{ textTransform: 'capitalize', fontWeight: 'bold' }}>
                                    {r.key}
                                </td>
                                <td className='py-1'>{r.value}</td>
                            </tr>
                        ))}
                </tbody>
            </table>
        </>
    )
}

export const DatasetRowRenderer = ({
    row,
    column,
    ...rest
}: DatasetRowRendererProps) => {
    const [showLookupFieldDetails, setShowLookupFieldDetails] = useState(false)

    const columnTypeMapper = {
        ShortText: () => {
            return <span>{row[column?.key]}</span>
        },
        LongText: () => {
            return <span>{row[column?.key]}</span>
        },
        Number: () => {
            return <span>{row[column?.key]}</span>
        },
        Phone: () => {
            return (
                <>
                    <span>
                        {row[column?.key]['number'] ? (
                            <>
                                {row[column?.key]['countryCode']}{' '}
                                {row[column?.key]['number']}
                            </>
                        ) : (
                            <></>
                        )}
                    </span>
                </>
            )
        },
        Email: () => {
            return <span>{row[column?.key]}</span>
        },
        Date: () => {
            return (
                <>
                    {row[column?.key] ? (
                        <Moment format="DD MMM, YYYY">{row[column?.key]}</Moment>
                    ) : (
                        <></>
                    )}
                </>
            )
        },
        Switch: () => {
            return <span>{row[column?.key]}</span>
        },
        Dropdown: () => {
            return <span>{row[column?.key]}</span>
        },
        SystemField: () => {
            return <span>{row[column?.key]}</span>
        },
        Radio: () => {
            return <span>{row[column?.key]}</span>
        },
        Checkbox: () => {
            return <span>{row[column?.key]?.join()}</span>
        },
        Lookup: () => {
            return (
                <span>
                    {!showLookupFieldDetails && (
                        <a
                            href="#!"
                            onClick={() => {
                                setShowLookupFieldDetails(true)
                            }}
                        >
                            {row[column?.key] && row[column?.key].key}
                        </a>
                    )}
                    {showLookupFieldDetails && (
                        <LookupTableData dataObject={row[column?.key]} />
                    )}
                </span>
            )
        },
        FileUpload: () => {
            return (
                <span>
                    {row[column?.key] &&
                    row[column?.key][0] &&
                    row[column?.key][0].file ? (
                        row[column?.key].map((file) => {
                            const parsedFile = parseFile(file)
                            return (
                                <a
                                    className="d-block"
                                    href={parsedFile.fileUrl || parsedFile.location}
                                    target="_blank"
                                    key={parsedFile._id || parsedFile._id}
                                >
                                    {parsedFile.fileName || parsedFile.name}
                                </a>
                            )
                        })
                    ) : (
                        <></>
                    )}
                </span>
            )
        },
    }

    return (
        <>
            {column.type
                ? columnTypeMapper[column.type]()
                : columnTypeMapper[determineFieldType(row, column)]()}
        </>
    )
}

DatasetRowRenderer.defaultProps = {
    row: {},
    column: {},
}
