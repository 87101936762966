import styles from './styles.module.css'
import React, { useState, useEffect } from 'react'
// import './styles.module.css'
import { BehaviorSubject } from 'rxjs'

interface Props {
  position: string
  autoDelete: boolean
  dismissTime: number
}

export const Toast = ({ position, autoDelete, dismissTime }: Props) => {
  // Input props for Toast
  const [list, setList] = useState([] as any)
  const [innerPosition, setPosition] = useState(styles.bottom_right)

  /*
   * Component initiated
   * 1. Set toast list
   */
  useEffect(() => {
    toastService.list.subscribe((response: any) => {
      if (list[0] && !list[0].id) {
        list.splice(0, 1)
      }
      setList(response)
    })
    switch (position) {
      case 'bottom-right':
        setPosition(styles.bottom_right)
        break
      case 'top-right':
        setPosition(styles.top_right)
        break
      case 'top-left':
        setPosition(styles.top_left)
        break
      case 'bottom-left':
        setPosition(styles.bottom_left)
        break
    }
  }, [])

  /*
   * Component initiated
   * 1. Hide the toaster after timeout
   * 2. Detect list changes only
   */
  useEffect(() => {
    const interval = setInterval(() => {
      if (autoDelete && list.length) {
        deleteToast(list[0].id)
      }
    }, dismissTime)

    return () => {
      clearInterval(interval)
    }
  }, [list])

  /*
   * Delete the toast and update list in toast service
   */
  const deleteToast = (id: string) => {
    const listItemIndex = list.findIndex((e: any) => e.id === id)
    list.splice(listItemIndex, 1)
    setList([...list])
    toastService.updateList(list)
  }

  /*
   * Render
   */
  return (
    <div>
      <div className={`${styles.notification_container} ${innerPosition}`}>
        {list.map((toast: any, i: any) => (
          <div
            key={i}
            className={`${styles.notification} ${styles.toast} ${innerPosition}`}
            style={{
              backgroundColor: toast.backgroundColor,
              color: toast.color
            }}
          >
            <div className='d-flex align-items-center'>
              {toast.title ? (
                <strong className={`${styles.notification_title} flex-grow-1`}>
                  {toast.title}
                </strong>
              ) : (
                ''
              )}
              <button
                style={{
                  color: toast.color,
                  paddingTop: '7px'
                }}
                onClick={() => deleteToast(toast.id)}
              >
                X
              </button>
            </div>
            {toast.description ? (
              <div className={styles.notification_message}>
                {toast.description}
              </div>
            ) : (
              ''
            )}
          </div>
        ))}
      </div>
    </div>
  )
}

/*
 * Toast list as a behavior subject
 */
const listSubject = new BehaviorSubject([] as any)

/*
 * Toast essentials
 */
let toastProperties: any
let list: any[] = []

/*
 * Private type: Not exposed
 */
const toastService = {
  updateList,
  list: listSubject.asObservable()
}

/*
 * Export as a type: exposed
 */
export const ToastService = {
  showToast
}

/*
 * Show toast generic method
 * 1. type [success, danger, info & warning]
 * 2. description
 * 3. title (optional)
 */
function showToast(type: string, description: string, title?: string) {
  const id = Math.floor(Math.random() * 101 + 1)

  switch (type) {
    case 'success':
      toastProperties = {
        id,
        title: title,
        description: description,
        backgroundColor: 'rgb(187, 229, 179)',
        color: 'rgb(80, 184, 60)'
      }
      break
    case 'danger':
      toastProperties = {
        id,
        title: title,
        description: description,
        backgroundColor: 'rgb(254, 173, 154)',
        color: 'rgb(222, 54, 24)'
      }
      break
    case 'info':
    default:
      toastProperties = {
        id,
        title: title,
        description: description,
        backgroundColor: 'rgb(180, 225, 250)',
        color: 'rgb(0, 111, 187)'
      }
      break
    case 'warning':
      toastProperties = {
        id,
        title: title,
        description: description,
        backgroundColor: 'rgb(255, 234, 138)',
        color: 'rgb(238, 194, 0)'
      }
      break
  }

  listSubject.next([...list, toastProperties])
  list.push(toastProperties)
}

/*
 * Update list if toast removed
 */
function updateList(modifiedList: any[]) {
  list = modifiedList
}
