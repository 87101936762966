import React from 'react'
import './Input.scss'
import { Form, FormControlProps } from 'react-bootstrap'

export type InputProps = FormControlProps & any

export const Input = (props: InputProps) => {
    let { className, type, name, innerRef, isInvalid, placeholder, ...rest } = props

    return (
        <Form.Control
            type={type}
            name={name}
            placeholder={placeholder}
            ref={innerRef}
            isInvalid={isInvalid}
            {...rest}
            className={className}
        />
    )
}

Input.defaultProps = {
    type: 'text',
    name: 'myInput',
    placeholder: '',
    className: ''
}