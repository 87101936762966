import React, { Suspense } from 'react'
import { AppLoader } from '../components/app-loader/AppLoader'
import { Toast } from '@angularminds/am-react-toaster'
import { AppNavigator } from '../components/app-navigator'

export default function App() {
    /*
     * Render
     */
    return (
        <Suspense fallback={<AppLoader />}>
            <Toast autoDelete={true} position="bottom-right" dismissTime={5000} />
            <AppNavigator />
        </Suspense>
    )
}
