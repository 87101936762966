import React, { useState } from 'react'
import { ChooseTemplate } from '../../../pages/flow-wizard/choose-template/choose-template'
import { randomCode } from '../../../services/general.service'
import { Drawer } from '../../drawer'
import { Link } from '../../inputs/link'
import { Loader } from '../../loader'
import { IMenu } from '../sidebar/Sidebar'
import './SubSidebar.scss'

export type SubSidebarProps = {
    /**
     * Sub Sidebar heading text
     */
    heading?: string
    /**
     * Hide sub-sidebar
     */
    hide?: boolean
    /**
     * Show create flow button
     */
    showCreateFlowButton?: boolean
    /**
     * Show searchbox
     */
    showSearchBox?: boolean
    /**
     * Menus list
     */
    menus: [IMenu]
    /**
     * Show loader
     */
    showLoader?: boolean
}

export const SubSidebar = ({
    heading,
    hide,
    menus,
    showCreateFlowButton,
    showSearchBox,
    showLoader,
    ...rest
}: SubSidebarProps) => {
    const [isFlowDrawerOpen, setFlowDrawerOpen] = useState(false)
    const [menuSearchTerm, setMenuSearchTerm] = useState('')

    return (
        <>
            <div className={hide ? 'd-none' : 'submenu bg-dark'}>
                <div className="submenu-inner bg-light">
                    <div className="d-flex submenu-header pb-1">
                        <h1 className="flex-grow-1 m-0">{heading}</h1>
                        {showCreateFlowButton && (
                            <a
                                href="#!"
                                onClick={(e) => {
                                    e.preventDefault()
                                    setFlowDrawerOpen(true)
                                }}
                            >
                                <i className="bx bx-plus text-brand"></i>
                            </a>
                        )}
                        <a href="#!">
                            <i className="bx bxs-chevron-left"></i>
                        </a>
                    </div>
                    {showSearchBox && (
                        <div className="submenu-search mt-1 mb-1">
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Search flow …"
                                onChange={(e) => {
                                    setMenuSearchTerm(e.target.value.toLowerCase())
                                }}
                            />
                        </div>
                    )}
                    {!showLoader && (
                        <ul
                            className={
                                menus.length > 12
                                    ? `list-unstyled mt-2 submenu-nav v-scroll`
                                    : `list-unstyled mt-2 submenu-nav`
                            }
                        >
                            {menus
                                .filter((menu) => {
                                    if (!menuSearchTerm) return true
                                    else {
                                        return menu.displayText
                                            ?.toLocaleLowerCase()
                                            .includes(menuSearchTerm)
                                    }
                                })
                                .map((menu) => {
                                    return (
                                        <li key={randomCode()}>
                                            <Link to={menu.url}>
                                                {menu.displayText}{' '}
                                                {menu.icon && (
                                                    <i className={menu.icon}></i>
                                                )}{' '}
                                            </Link>
                                        </li>
                                    )
                                })}
                        </ul>
                    )}
                    {showLoader && <Loader className="text-center mt-3" />}
                </div>
                <div className="submenu-minimized-trigger bg-light">
                    <a href="#!">
                        <i className="bx bxs-chevron-right"></i>
                    </a>
                </div>
            </div>
            <Drawer
                isOpen={isFlowDrawerOpen}
                position="left"
                isFullscreen={true}
                onClose={() => setFlowDrawerOpen(false)}
            >
                <ChooseTemplate onClose={() => setFlowDrawerOpen(false)} />
            </Drawer>
        </>
    )
}

SubSidebar.defaultProps = {
    heading: 'Heading goes here',
    menus: [
        {
            id: '1',
            url: '/menu-1',
            displayText: 'Menu 1',
        },
        {
            id: '2',
            url: '/menu-2',
            displayText: 'Menu 2',
        },
        {
            id: 'n',
            url: '/menu-n',
            displayText: 'Menu n',
        },
    ],
}
