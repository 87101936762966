export const sidebarRoutes = {
    mainApp: {
        logo: '/images/99flows-logo.png',
        name: '99Flows',
        menus: [
            {
                displayText: 'Inbox',
                id: 'inbox',
                url: '/inbox',
                icon: `bx bxs-inbox`,
            },
            {
                displayText: 'Flows',
                id: 'flows',
                url: '/flows',
                icon: `bx bxs-package`,
            },
            {
                displayText: 'Datasets',
                id: 'datasets',
                url: '/datasets',
                icon: `bx bx-data`,
            },
            {
                displayText: 'Automation',
                id: 'automations',
                url: '/automations',
                icon: `bx bx-bot`,
            },
            // {
            //     displayText: 'Groups',
            //     id: 'groups',
            //     url: '/',
            //     icon: `bx bx-group`,
            // },
        ],
        userProfile: {
            avatar: '/images/default-profile-pic.png',
            options: [
                {
                    displayText: 'My profile',
                    id: 'my-profile',
                    url: '/profile',
                    icon: `bx bx-user bx-fw`,
                },
                {
                    displayText: 'Settings',
                    id: '/settings/general',
                    url: '/settings/general',
                    icon: `bx bx-cog bx-fw`,
                },
                {
                    displayText: `What's new`,
                    id: 'what-new',
                    url: '/coming-soon',
                    icon: `bx bxs-megaphone bx-fw`,
                },
                {
                    displayText: 'Contact support',
                    id: 'support',
                    url: '/coming-soon',
                    icon: `bx bx-support bx-fw`,
                },
            ],
        },
    },
    // Add your sidebar route here
}
