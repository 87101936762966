export interface Field {
    _id?: string
    label: string
    key: string
    isEditing?: boolean
    instructions?: string
    type: string
    sequenceNo?: number
    value?: any
    metaData?: MetaData
    permissionForTask?: string
}

export interface MetaData {
    display?: MetaDataDisplay
    initialValue?: MetaDataInitialValue
    options?: MetaDataOption[]
    required?: boolean
    minValue?: string
    maxValue?: string
    minLength?: number
    maxLength?: number
    minRow?: number
    maxRow?: number
    allowNegative?: boolean
    noDuplicates?: boolean
    phone?: MetaDataPhone
    decimal?: number
    date?: MetaDataDate
    file?: MetaDataFile
    switchValue?: MetaDataSwitch
    datasetRows?: any[]
}

export interface MetaDataOption {
    displayText: string
    value: string
}

export interface MetaDataDisplay {
    computeField: boolean
    columns: number
}

export interface MetaDataInitialValue {
    value: string
    formula: string
}

export interface MetaDataPhone {
    includeCountry: boolean
    defaultCountryCode: string
}

export interface MetaDataDate {
    minDate: MetaDataInitialValue
    maxDate: MetaDataInitialValue
}

export interface MetaDataSwitch {
    checkedValue: string
    unCheckedValue: string
}

export interface MetaDataFile {
    allowMultiple: boolean
    allowedTypes: any[]
    maxSize: number
}

export enum SectionType {
    Section = 'Section',
    Table = 'Table',
}
export interface Section {
    _id?: string
    type: SectionType
    name: string
    sequenceNumber: number
    isEditing: boolean
    isEditingTable: boolean
    fields: Field[]
    // Only needed when section.type = Table
    metaData?: MetaData
}

export enum FieldTypes {
    ShortText = 'ShortText',
    LongText = 'LongText',
    Number = 'Number',
    Phone = 'Phone',
    Email = 'Email',
    Date = 'Date',
    Switch = 'Switch',
    Dropdown = 'Dropdown',
    Radio = 'Radio',
    FileUpload = 'FileUpload',
    Table = 'Table',
    Checkbox = 'Checkbox',
    Lookup = 'Lookup',
}

export enum FieldActions {
    Edit = 'Edit',
    Delete = 'Delete',
}

export enum FieldPermissionActions {
    Editable = 'Editable',
    Readonly = 'Readonly',
    Hidden = 'Hidden',
}

export enum AddButtonHolderActions {
    AddField = 'AddField',
    AddTable = 'AddTable',
    AddSection = 'AddSection',
}

export const defaultSections = [
    {
        name: 'Untitled section',
        isEditing: false,
        type: SectionType.Section,
        sequenceNumber: 1,
        fields: [] as Field[],
    } as Section,
    {
        name: 'Untitled section 1',
        isEditing: false,
        type: SectionType.Section,
        sequenceNumber: 1,
        fields: [] as Field[],
    } as Section,
]

export const defaultTableSections = [
    {
        name: 'Untitled table',
        isEditing: false,
        type: SectionType.Table,
        isEditingTable: true,
        sequenceNumber: 2,
        fields: [] as Field[],
    } as Section,
]

export const defaultOptions: MetaDataOption[] = [
    {
        displayText: '',
        value: '',
    },
]

export const defaultFields: Field[] = [
    {
        key: 'first_name',
        label: 'First name',
        type: FieldTypes.ShortText,
    },
    {
        key: 'last_name',
        label: 'Last name',
        type: FieldTypes.ShortText,
    },
    {
        key: 'email',
        label: 'Email',
        type: FieldTypes.Email,
    },
    {
        key: 'age',
        label: 'Age',
        type: FieldTypes.Number,
    },
]
