import React, { useState, useEffect } from 'react'
import { Form } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import { FlowCard } from '../../../components/flow-card'
import { Button } from '../../../components/inputs/button'
import { Select } from '../../../components/inputs/select'
import { SelectDropdown } from '../../../components/inputs/select-dropdown'
import { Input } from '../../../components/inputs/text-input'
import { CustomModal } from '../../../components/modal'
import history from '../../../routes/history'
import { randomCode } from '../../../services/general.service'
import { get, post } from '../../../services/http/httpMethods'
import { showErrorToast, showSuccessToast } from '../../../utils/toastUtil'
import './choose-template.scss'

export type ChooseTemplateProps = {
    /**
     * On close
     */
    onClose?: any
}

export const ChooseTemplate = ({ onClose }: ChooseTemplateProps) => {
    const { register, handleSubmit, errors, reset } = useForm()
    const [isButtonDisabled, setButtonDisable] = useState(false)
    const [categories, setCategories] = useState([] as any)
    const [showForm, setShowForm] = useState(false)
    const [categoriesPayload, setCategoriesPayload] = useState([] as any)

    const submitForm = (formData) => {
        setButtonDisable(true)

        post(`/api/flows`, { ...formData, categories: categoriesPayload })
            .then((response: any) => {
                setButtonDisable(false)
                showSuccessToast(response.message)
                history.push(`/flow/${response.data}/design-form`)
            })
            .catch((error) => {
                setButtonDisable(false)
                showErrorToast(error.message)
            })
    }

    const fetchCategories = () => {
        get(`/api/categories`)
            .then((categories) => {
                setCategories(categories)
            })
            .catch((error) => {
                showErrorToast(
                    error.message || 'Error occurred while fetching categories!'
                )
            })
    }

    const handleCategories = (innerCategories) => {
        setCategoriesPayload(innerCategories.map((e) => e.value))
    }

    useEffect(fetchCategories, [])

    /*
     * Render
     */
    return (
        <>
            <div className="create-flow-starter">
                <div className="container my-5">
                    <h1 className="mb-5">Create flow</h1>
                    {!showForm && (
                        <div className="row mx-n2">
                            <div className="col-4 col-lg-3 px-2">
                                <div className="card card-hover">
                                    <div className="card-body">
                                        <button
                                            onClick={(e) => {
                                                setShowForm(true)
                                            }}
                                            type="button"
                                            className="btn btn-brand rounded-circle btn-create-own-flow stretched-link"
                                        >
                                            <i className="bx bx-plus text-white"></i>
                                        </button>
                                        <h5 className="mt-3">Create your own</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                    {showForm && (
                        <div className="card create-flow-form">
                            <div className="card-body">
                                <Form onSubmit={handleSubmit(submitForm)}>
                                    <Form.Group>
                                        <Form.Label>Name</Form.Label>
                                        <Input
                                            type="text"
                                            name="name"
                                            placeholder="Enter flow name"
                                            innerRef={register({
                                                required: true,
                                            })}
                                            isInvalid={errors.name}
                                        />
                                        {errors.name && (
                                            <Form.Control.Feedback type="invalid">
                                                Name is required
                                            </Form.Control.Feedback>
                                        )}
                                    </Form.Group>
                                    <Form.Group>
                                        <Form.Label>
                                            Description (optional)
                                        </Form.Label>
                                        <Input
                                            as="textarea"
                                            name="description"
                                            rows={3}
                                            placeholder="Enter description"
                                            innerRef={register({
                                                required: false,
                                            })}
                                            isInvalid={errors.description}
                                        />
                                        {errors.description && (
                                            <Form.Control.Feedback type="invalid">
                                                Description is required
                                            </Form.Control.Feedback>
                                        )}
                                    </Form.Group>
                                    <Form.Group>
                                        <Form.Label>Category</Form.Label>
                                        <SelectDropdown
                                            name="categories"
                                            options={categories.map((e) => {
                                                return {
                                                    value: e._id,
                                                    label: e.name,
                                                }
                                            })}
                                            isMulti={true}
                                            innerRef={register({
                                                required: false,
                                            })}
                                            onChange={handleCategories}
                                            isInvalid={errors.allowedTypes}
                                        />
                                        {errors.categories && (
                                            <Form.Control.Feedback type="invalid">
                                                Categories are required
                                            </Form.Control.Feedback>
                                        )}
                                    </Form.Group>
                                    <Button
                                        className="btn btn-primary px-4"
                                        type="submit"
                                        isLoading={isButtonDisabled}
                                        text="Save"
                                    />
                                    <Button
                                        className="btn btn-light ml-2"
                                        type="button"
                                        text="Cancel"
                                        onClick={(e) => {
                                            setShowForm(false)
                                        }}
                                    />
                                </Form>
                            </div>
                        </div>
                    )}
                    {!showForm && (
                        <>
                            <h4 className="mt-5 mb-3">Create from templates</h4>
                            <div className="row row-cols-sm-3 row-cols-lg-4 mx-n2">
                                <div className="col px-2 mb-3">
                                    <FlowCard />
                                </div>
                                <div className="col px-2 mb-3">
                                    <FlowCard />
                                </div>
                            </div>
                        </>
                    )}
                </div>
            </div>

            <div className="hide-roller vh-100 bg-light">
                <button
                    type="button"
                    className="btn btn-light btn-chevron btn-chevron-right"
                    onClick={(e) => {
                        onClose(true)
                    }}
                >
                    <i className="bx bxs-chevron-left"></i>
                </button>
            </div>
        </>
    )
}
