import { subSidebarRoutes } from '../routes/sub.sidebar.routes.config'

/*
 * Generate 6 digits random code
 */
export const randomCode = () => {
    return Math.floor(100000 + Math.random() * 900000)
}

/*
 * Find indices of all matching elements in array
 */
export const findIndices = (fedArray: Array<any>, expression) => {
    return fedArray
        .map((element, index) =>
            element[expression.key] === expression.value ? index : ''
        )
        .filter(String)
}

/*
 * Find days in this month
 */
export const daysInThisMonth = () => {
    var now = new Date()
    return new Date(now.getFullYear(), now.getMonth() + 1, 0).getDate()
}

/*
 * Get last two parts of URL separated by /
 */
export const getLastTwoPartsOfURL = (path) => {
    const paths = path.split('/')
    // Return last three parts on URL
    if (paths.length === 6) {
        return (
            '/' +
            paths[paths.length - 3] +
            '/' +
            paths[paths.length - 2] +
            (paths[paths.length - 2] === 'dashboards'
                ? '/:dashboardId'
                : '/:reportId')
        )
    } else {
        // Return last two parts of the URL
        return '/' + paths[paths.length - 2] + '/' + paths[paths.length - 1]
    }
}

/*
 * Compute path for sub sidebar routes
 */
export const computePathForSubSidebar = (path) => {
    if (subSidebarRoutes[path]) {
        return subSidebarRoutes[path]
    }

    if (path.includes('/flows/')) {
        return subSidebarRoutes[getLastTwoPartsOfURL(path)]
    } else if (path.includes('/inbox/')) {
        return subSidebarRoutes['/inbox/:processId']
    } else if (path.includes('/datasets/')) {
        return subSidebarRoutes['/datasets/:datasetId']
    } else if (path.includes('/automation/')) {
        return subSidebarRoutes['/automation/:automationId']
    }
}

/*
 * Compute error register object
 */
export const computeErrorRegisterObject = (field, skipValidation?: boolean) => {
    return field.permissionForTask === 'Readonly' || skipValidation
        ? { required: false }
        : {
              required: field?.metaData?.required,
              maxLength: {
                  value: field?.metaData?.maxLength || Number.MAX_SAFE_INTEGER,
                  message: `Max length ${
                      field?.metaData?.maxLength || Number.MAX_SAFE_INTEGER
                  } is exceeded`,
              },
              minLength: {
                  value: field?.metaData?.minLength || 0,
                  message: `Min length should be ${field?.metaData?.minLength || 0}`,
              },
          }
}

/*
 * Convert object into query params
 */
export const convertObjectToQueryParams = (filter) => {
    if (!filter) return ''
    return (
        `?` +
        Object.keys(filter)
            .map((key) => `${key}=${filter[key]}`)
            .join('&')
    )
}

/*
 * Load script
 */
export const loadScript = (src) => {
    return new Promise((resolve) => {
        const script = document.createElement('script')
        script.src = src
        script.onload = () => {
            resolve(true)
        }
        script.onerror = () => {
            resolve(false)
        }
        document.body.appendChild(script)
    })
}

/**
 * this will convert object into array
 * @param object object data
 * Output:
 * [
 *  {
 *    key: string,
 *    value: any
 *  }
 * ]
 */
export const toArray = (object: any) => {
    const output: any[] = []
    for (const key in object) {
        if (key !== undefined) {
            output.push({
                key,
                value: object[key],
            })
        }
    }
    return output
}

/*
 * Generate random color
 */
export const getRandomColor = () => {
    var letters = '0123456789ABCDEF'
    var color = '#'
    for (var i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)]
    }
    return color
}

/*
 * Generate random colors till count
 */
export const getRandomColors = (count: number) => {
    const colors: string[] = []
    for (let i = 0; i < count; i++) {
        colors.push(getRandomColor())
    }

    return colors
}
