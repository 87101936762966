import React from 'react'
import './Loader.scss'

export type LoaderProps = {
    type: 'dots' | 'overlay'
} & React.HTMLAttributes<HTMLSpanElement>

export const Loader = ({ className, type, ...rest }: LoaderProps) => {
    return (
        <>
            {type === 'dots' && (
                <div {...rest} className={className}>
                    <span className="progress-dots">
                        <i></i>
                        <i></i>
                        <i></i>
                        <i></i>
                    </span>
                </div>
            )}
            {type === 'overlay' && (
                <div {...rest} className={`overlay ${className}`}>
                    <Loader type="dots" />
                </div>
            )}
        </>
        //   Old loader code
        // <div {...rest} className={className + ' dots-loader'}>
        //   <span>●</span>
        //   <span>●</span>
        //   <span>●</span>
        // </div>
    )
}

Loader.defaultProps = {
    type: 'dots',
}
