import { lazy, LazyExoticComponent } from 'react'

// Lazy load your page components
const Login = lazy(() => import('../pages/auth/login/login'))
const Register = lazy(() => import('../pages/auth/register/register'))
const GeneralSettings = lazy(() => import('../pages/settings/general/general'))
const Profile = lazy(() => import('../pages/profile/profile'))
const UsersTeamsNavigator = lazy(
    () =>
        import('../pages/settings/users/users-teams-navigator/users-teams-navigator')
)
const UserList = lazy(() => import('../pages/settings/users/user-list/user-list'))
const TeamList = lazy(() => import('../pages/settings/users/team-list/team-list'))
const ActivateUser = lazy(() => import('../pages/auth/activate-user/activate-user'))
const AcceptInvite = lazy(() => import('../pages/auth/accept-invite/accept-invite'))
const ForgotPassword = lazy(
    () => import('../pages/auth/forgot-password/forgot-password')
)
const SetPassword = lazy(() => import('../pages/auth/set-password/set-password'))
const SecondFactor = lazy(() => import('../pages/auth/second-factor/second-factor'))
const Inbox = lazy(() => import('../pages/inbox/inbox'))

const DefineFlow = lazy(() => import('../pages/flow-wizard/define-flow/define-flow'))
const Deploy = lazy(() => import('../pages/flow-wizard/deploy/deploy'))
const DesignForm = lazy(() => import('../pages/flow-wizard/design-form/design-form'))
const Permissions = lazy(
    () => import('../pages/flow-wizard/permissions/permissions')
)
const FlowWizardNavigator = lazy(
    () => import('../pages/flow-wizard/flow-wizard-navigator/flow-wizard-navigator')
)
const FlowDashboardNavigator = lazy(
    () =>
        import(
            '../pages/flow-dashboard/flow-dashboard-navigator/flow-dashboard-navigator'
        )
)
const MyRequests = lazy(
    () => import('../pages/flow-dashboard/my-requests/my-requests')
)
const AllRequests = lazy(
    () => import('../pages/flow-dashboard/all-requests/all-requests')
)
const Insights = lazy(() => import('../pages/flow-dashboard/insights/insights'))
const Reports = lazy(() => import('../pages/flow-dashboard/reports/reports'))
const Dashboards = lazy(
    () => import('../pages/flow-dashboard/dashboards/dashboards')
)
const Settings = lazy(() => import('../pages/flow-dashboard/settings/settings'))
const ProcessDetails = lazy(() => import('../pages/process-details/process-details'))
const Flows = lazy(() => import('../pages/flows/flows'))
const DatasetList = lazy(() => import('../pages/dataset/dataset-list/dataset-list'))
const DatasetDetails = lazy(
    () => import('../pages/dataset/dataset-details/dataset-details')
)
const AutomationList = lazy(
    () => import('../pages/automation/automation-list/automation-list')
)
const AutomationActions = lazy(
    () => import('../pages/automation/automation-actions/automation-actions')
)
const ReportDetails = lazy(
    () => import('../pages/flow-dashboard/report-details/report-details')
)
const DashboardDetails = lazy(
    () => import('../pages/flow-dashboard/dashboard-details/dashboard-details')
)

/*
 * Route path: URLs
 */
export const paths = {
    defaultPostLogin: '/inbox',
    login: '/auth/login',
    register: '/auth/register',
    activateUser: '/auth/activate-user',
    acceptInvite: '/auth/accept-invite',
    forgotPassword: '/auth/forgot-password',
    setPassword: '/auth/set-password',
    secondFactor: '/auth/second-factor',
    profile: '/profile',
    genealSettings: '/settings/general',
    usersSettings: '/settings/:entity',
    teamsSettings: '/settings/:entity',
    inbox: '/inbox',
    inboxFiltered: '/inbox/filtered/:flowKey',
    flowDashboard: '/flows/:flowKey/dashboard/:dashboardTab',
    flowReportDetails: '/flows/:flowKey/dashboard/:dashboardTab/:reportId',
    flowDashboardDetails: '/flows/:flowKey/dashboard/:dashboardTab/:dashboardId',
    chooseAppTemplate: '/flow/choose-template',
    flowWizard: '/flow/:flowId/:flowTab',
    processDetails: '/inbox/:processKey',
    flows: '/flows',
    datasetList: '/datasets',
    datasetDetails: '/datasets/:datasetId',
    automationList: '/automations',
    automationActions: '/automation/:automationId',
}

/*
 * Components
 */
export const components = {
    UserList,
    TeamList,
    DefineFlow,
    DesignForm,
    Deploy,
    Permissions,
    FlowWizardNavigator,
    Inbox,
    MyRequests,
    AllRequests,
    Insights,
    Reports,
    Settings,
    ReportDetails,
    Dashboards,
    DashboardDetails,
}

/*
 * Type defined for path component
 */
interface PathComponent {
    path: string
    component: LazyExoticComponent<(props: any) => JSX.Element>
}

/*
 * Routes: path & lazy loaded component
 */
export const routes: PathComponent[] = [
    {
        path: paths.login,
        component: Login,
    },
    {
        path: paths.register,
        component: Register,
    },
    {
        path: paths.activateUser,
        component: ActivateUser,
    },
    {
        path: paths.acceptInvite,
        component: AcceptInvite,
    },
    {
        path: paths.forgotPassword,
        component: ForgotPassword,
    },
    {
        path: paths.setPassword,
        component: SetPassword,
    },
    {
        path: paths.secondFactor,
        component: SecondFactor,
    },
    {
        path: paths.profile,
        component: Profile,
    },
    {
        path: paths.genealSettings,
        component: GeneralSettings,
    },
    {
        path: paths.usersSettings,
        component: UsersTeamsNavigator,
    },
    {
        path: paths.teamsSettings,
        component: UsersTeamsNavigator,
    },
    {
        path: paths.inbox,
        component: Inbox,
    },
    {
        path: paths.inboxFiltered,
        component: Inbox,
    },
    {
        path: paths.flowDashboard,
        component: FlowDashboardNavigator,
    },
    {
        path: paths.flowReportDetails,
        component: FlowDashboardNavigator,
    },
    {
        path: paths.flowDashboardDetails,
        component: FlowDashboardNavigator,
    },
    {
        path: paths.flowWizard,
        component: FlowWizardNavigator,
    },
    {
        path: paths.processDetails,
        component: ProcessDetails,
    },
    {
        path: paths.flows,
        component: Flows,
    },
    {
        path: paths.datasetList,
        component: DatasetList,
    },
    {
        path: paths.datasetDetails,
        component: DatasetDetails,
    },
    {
        path: paths.automationList,
        component: AutomationList,
    },
    {
        path: paths.automationActions,
        component: AutomationActions,
    },
]
