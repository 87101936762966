import { BehaviorSubject } from 'rxjs'
import { showErrorToast } from '../utils/toastUtil'
import { authenticationService } from './auth.service'
import { convertObjectToQueryParams } from './general.service'
import { del, get, post, put } from './http/httpMethods'
import { Flow } from './interfaces/flow'

const flowsSubject = new BehaviorSubject(undefined)
const flowSubject = new BehaviorSubject(undefined)
const tasksSubject = new BehaviorSubject(undefined)

/*
 * Export as a Type
 */
export const flowService = {
    fetchFlows,
    fetchFlow,
    startFlow,
    fetchTaskAndProcess,
    fetchTasksByUser,
    submitTask,
    changeAssignee,
    updateFlow,
    fetchMyRequests,
    getProgressClass,
    isUserInAssignee,
    addComment,
    fetchComments,
    fetchActivities,
    fetchReportsByFlow,
    fetchReport,
    fetchReportRows,
    populateFlowFields,
    addColumnsToReport,
    removeColumnsFromReport,
    createReport,
    archiveReport,
    sortByOriginalArray,
    updateColumnSortOrder,
    createDashboard,
    fetchDashboard,
    fetchDashboardsByFlow,
    archiveDashboard,
    createChart,
    updateChart,
    deleteChart,
    combineFields,
    flowsSubject: flowsSubject.asObservable(),
    flowSubject: flowSubject.asObservable(),
    tasksSubject: tasksSubject.asObservable(),
    get flows() {
        return flowsSubject.value
    },
    get flow() {
        return flowSubject.value
    },
}

/*
 * Fetch flows
 */
function fetchFlows(positiveCountsOnly = false, searchQuery = '') {
    flowsSubject.next({ showLoader: true } as any)
    get(
        `/api/flows?positiveCountsOnly=${positiveCountsOnly}&searchQuery=${searchQuery}`
    )
        .then((response) => {
            flowsSubject.next(response.data)
        })
        .catch((error) => {
            showErrorToast(error.message)
        })
}

/*
 * Fetch flow
 */
function fetchFlow(flowKey) {
    flowSubject.next({ showLoader: true } as any)
    get(`/api/flows/${flowKey}/dashboard`)
        .then((response) => {
            flowSubject.next(response.data)
        })
        .catch((error) => {
            showErrorToast(error.message)
        })
}

/*
 * Fetch TODO tasks by user
 */
function fetchTasksByUser(filter?) {
    const queryParams = convertObjectToQueryParams(filter)
    tasksSubject.next({ showLoader: true } as any)
    return get(`/api/tasks${queryParams}`)
        .then((response: any) => {
            tasksSubject.next(response.data)
        })
        .catch((error) => {
            showErrorToast(error.message)
        })
}

/*
 * Fetch my requests
 */
function fetchMyRequests(filter?) {
    const queryParams = convertObjectToQueryParams(filter)
    return get(`/api/tasks/my/requests${queryParams}`).then((response: any) => {
        return response
    })
}

/*
 * Get progress class
 */
function getProgressClass(percentage: number) {
    if (percentage <= 25) {
        return `bg-warning`
    } else if (percentage > 25) {
        return `bg-success`
    }
}

/*
 * Start flow
 */
function startFlow(flowId) {
    return get(`/api/processes/${flowId}/start`).then((response: any) => {
        return response
    })
}

/*
 * Fetch task and its process
 */
function fetchTaskAndProcess(processKey, taskId = '', userId = '') {
    return get(`/api/tasks/${processKey}?taskId=${taskId}&userId=${userId}`).then(
        (response: any) => {
            return response
        }
    )
}

/*
 * Is user in assignee
 */
function isUserInAssignee(users) {
    return users
        .map((e) => e._id)
        .includes(authenticationService.currentUserValue._id)
}

/*
 * Submit task
 */
function submitTask(taskId, payload, processKey) {
    return post(
        `/api/tasks/${taskId}/submit?processKey=${processKey}`,
        payload
    ).then((response: any) => {
        return response
    })
}

/*
 * Add comment
 */
function addComment(payload) {
    return post(`/api/comments`, payload).then((response: any) => {
        return response
    })
}

/*
 * Fetch comments
 */
function fetchComments(processId: string) {
    return get(`/api/comments/${processId}`).then((response: any) => {
        return response
    })
}

/*
 * Fetch activities
 */
function fetchActivities(processId: string) {
    return get(`/api/activities/${processId}`).then((response: any) => {
        return response
    })
}

/*
 * Change assignee
 */
function changeAssignee(taskId, payload, processKey) {
    return put(`/api/tasks/${taskId}?processKey=${processKey}`, payload).then(
        (response: any) => {
            return response
        }
    )
}

/*
 * Update flow
 */
function updateFlow(flow: Flow) {
    return put(`/api/flows/${flow._id}`, flow).then((response: any) => {
        return response
    })
}

/*
 * Fetch reports
 */
function fetchReportsByFlow(flowKey: string) {
    return get(`/api/reports?flowKey=${flowKey}`).then((response: any) => {
        return response
    })
}

/*
 * Fetch report
 */
function fetchReport(reportId: string) {
    return get(`/api/reports/${reportId}`).then((response: any) => {
        return response
    })
}

/*
 * Fetch report rows
 */
function fetchReportRows(flowId: string, queryParamsString: string) {
    return get(`/api/process-data/reports/${flowId}${queryParamsString}`).then(
        (response: any) => {
            return response
        }
    )
}

/*
 * Add columns to report
 */
function addColumnsToReport(reportId: string, columns: any[]) {
    return post(`/api/reports/${reportId}/columns`, columns).then(
        (response: any) => {
            return response
        }
    )
}

/*
 * Remove columns from report
 */
function removeColumnsFromReport(reportId: string, columnIds: string[]) {
    return del(`/api/reports/${reportId}/columns?columnIds=${columnIds}`).then(
        (response: any) => {
            return response
        }
    )
}

/*
 * Create report
 */
function createReport(payload: any) {
    return post(`/api/reports`, payload).then((response: any) => {
        return response
    })
}

/*
 * Archive report
 */
function archiveReport(reportId: string) {
    return del(`/api/reports/${reportId}`).then((response: any) => {
        return response
    })
}

/*
 * Populate flow fields
 */
function populateFlowFields(flow: any) {
    let fields: any[] = []
    flow?.sections?.forEach((section) => {
        fields = fields.concat(
            section.fields?.map((field) => {
                return {
                    key: field.key,
                    value: field.key,
                    field: field._id,
                    label: field.label,
                    type: field.type,
                }
            })
        )
    })

    return fields
}

/*
 * Update column's sort order
 */
function updateColumnSortOrder(
    reportId: string,
    columnId: string,
    sortOrder: string
) {
    return put(`/api/reports/${reportId}/${columnId}/column/${sortOrder}`, {}).then(
        (response: any) => {
            return response
        }
    )
}

/*
 * Sort by original array
 */
function sortByOriginalArray(originalArray: any[], toBeSortedArray: any[]) {
    toBeSortedArray = toBeSortedArray.map((i) => i.key)
    const sortedArray: any[] = []
    for (let i = 0; i < originalArray.length; i++) {
        if (sortedArray.length === originalArray.length) break
        if (toBeSortedArray.includes(originalArray[i].key)) {
            sortedArray.push(originalArray[i])
        }
    }

    return sortedArray
}

/*
 * Create dashboard
 */
function createDashboard(payload: any) {
    return post(`/api/dashboards`, payload).then((response: any) => {
        return response
    })
}

/*
 * Fetch dashboards
 */
function fetchDashboardsByFlow(flowKey: string) {
    return get(`/api/dashboards?flowKey=${flowKey}`).then((response: any) => {
        return response
    })
}

/*
 * Fetch dashboard
 */
function fetchDashboard(dashboardId: string) {
    return get(`/api/dashboards/${dashboardId}`).then((response: any) => {
        return response
    })
}

/*
 * Archive dashboard
 */
function archiveDashboard(dashboardId: string) {
    return del(`/api/dashboards/${dashboardId}`).then((response: any) => {
        return response
    })
}

/*
 * Create chart
 */
function createChart(dashboardId: string, payload: any) {
    return post(`/api/dashboards/chart/${dashboardId}`, payload).then(
        (response: any) => {
            return response
        }
    )
}

/*
 * Update chart
 */
function updateChart(dashboardId: string, chartId: string, payload: any) {
    return put(`/api/dashboards/chart/${dashboardId}/${chartId}`, payload).then(
        (response: any) => {
            return response
        }
    )
}

/*
 * Delete chart
 */
function deleteChart(dashboardId: string, chartId: string) {
    return del(`/api/dashboards/chart/${dashboardId}/${chartId}`).then(
        (response: any) => {
            return response
        }
    )
}

/*
 * Combine flow and system fields
 */
function combineFields(
    flowName: string,
    flowFields: any[],
    systemFields: any[],
    showCount: boolean
) {
    return showCount
        ? [
              {
                  label: flowName,
                  options: flowFields.map((f) => {
                      return {
                          ...f,
                          label: `${f.label} (Count)`,
                      }
                  }),
              },
          ].concat([
              {
                  label: 'System fields',
                  options: systemFields.map((sf) => {
                      return { ...sf, value: sf.key, label: `${sf.label} (Count)` }
                  }),
              },
          ])
        : [{ label: flowName, options: flowFields }].concat([
              {
                  label: 'System fields',
                  options: systemFields.map((sf) => {
                      return { ...sf, value: sf.key }
                  }),
              },
          ])
}
