import React, { useState, useEffect } from 'react'
import { Form } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import { FlowCard } from '../../components/flow-card'
import { Button } from '../../components/inputs/button'
import { SearchInput } from '../../components/inputs/search-input/SearchInput'
import { Select } from '../../components/inputs/select'
import { Input } from '../../components/inputs/text-input'
import { Loader } from '../../components/loader'
import history from '../../routes/history'
import { flowService } from '../../services/flow.service'
import { randomCode } from '../../services/general.service'
import { get, post } from '../../services/http/httpMethods'
import { showErrorToast, showSuccessToast } from '../../utils/toastUtil'
import './initiate-request.scss'

export type InitiateRequestProps = {
    /**
     * On close
     */
    onClose?: any
}

export const InitiateRequest = ({ onClose }: InitiateRequestProps) => {
    const { register, handleSubmit, errors, reset } = useForm()
    const [isButtonDisabled, setButtonDisable] = useState(false)
    const [flows, setFlows] = useState([] as any)

    useEffect(() => {
        let flowsSubscription: any
        flowsSubscription = flowService.flowsSubject.subscribe((flows: any) => {
            setFlows(flows)
        })
        return () => {
            flowsSubscription.unsubscribe()
        }
    }, [])

    const searchFlows = (e) => {
        get(`/api/flows?searchQuery=${e.target.value}`)
            .then((response) => {
                setFlows(response.data)
            })
            .catch((error) => {
                showErrorToast(error.message)
            })
    }

    const startFlow = (flow) => {
        flow.isStarting = true
        setFlows([...flows])
        flowService
            .startFlow(flow._id)
            .then((response) => {
                flow.isStarting = false
                setFlows([...flows])
                if (response.data && response.data.task) {
                    history.push(`/inbox/${response.data.process.key}`)
                } else {
                    flowService.fetchTasksByUser({ flowIds: [flow._id] })
                }
                showSuccessToast(`Request initiated successfully.`)
            })
            .catch((error) => {
                flow.isStarting = false
                setFlows([...flows])
                showErrorToast(error.message)
            })
    }

    /*
     * Render
     */
    return (
        <>
            <div className="initiate-flow">
                <div className="container mt-5 mb-2">
                    <h1 className="text-center">Initiate a request</h1>
                    <div className="d-flex justify-content-center">
                        <SearchInput
                            type="text"
                            name="name"
                            className="search-flow-input"
                            placeholder="Search"
                            innerRef={register({
                                required: true,
                            })}
                            isInvalid={errors.name}
                            onChange={searchFlows}
                            autoComplete="off"
                        />
                    </div>
                    <div className="d-flex justify-content-center my-2">
                        <div className="search-results-text">MOST USED FLOWS</div>
                        <div></div>
                    </div>
                    <div className="d-flex justify-content-center">
                        <div
                            className={
                                flows.length > 5
                                    ? `search-results vertical-scroll`
                                    : `search-results`
                            }
                        >
                            {flows.length ? (
                                flows.map((flow) => (
                                    <div
                                        className="search-bg"
                                        onClick={(e) => {
                                            if (flow.isStarting) return
                                            startFlow(flow)
                                        }}
                                        key={flow._id}
                                    >
                                        <div className="search-item">
                                            {!flow.isStarting && (
                                                <div className="d-flex">
                                                    <>
                                                        <img
                                                            src={
                                                                flow.logo ||
                                                                `/images/default-profile-pic.png`
                                                            }
                                                            height={32}
                                                            width={32}
                                                            alt=""
                                                        />
                                                        <div className="flex-grow-1 pl-3 d-flex flex-column">
                                                            <strong>
                                                                {flow.name}
                                                            </strong>
                                                            <small>
                                                                {flow.description}
                                                            </small>
                                                        </div>
                                                    </>
                                                </div>
                                            )}
                                            {flow.isStarting && (
                                                <div className="d-flex align-items-center">
                                                    <Loader className="flex-grow-1 pt-2 text-center" />{' '}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                ))
                            ) : (
                                <></>
                            )}
                        </div>
                    </div>
                    <div className="text-muted text-center mt-2">Press ESC to exit.</div>
                </div>
            </div>
            <div className="hide-roller vh-100 bg-light">
                <button
                    type="button"
                    className="btn btn-light btn-chevron btn-chevron-right"
                    onClick={(e) => {
                        onClose(true)
                    }}
                >
                    <i className="bx bxs-chevron-left"></i>
                </button>
            </div>
        </>
    )
}
