import React, { useState, useEffect } from 'react'
import { Loader } from '../../components/loader'
import history from '../../routes/history'
import { authenticationService } from '../../services/auth.service'
import { get, put } from '../../services/http/httpMethods'
import { showErrorToast } from '../../utils/toastUtil'
import './notifications.scss'
import Moment from 'react-moment'
import Avatar from 'react-avatar'

export type NotificationsProps = {
    /**
     * On close
     */
    onClose?: any
}

export const Notifications = ({ onClose }: NotificationsProps) => {
    const [notifications, setNotifications] = useState([] as any)
    const [showLoader, setShowLoader] = useState(false)
    const [markingAsRead, setMarkingAsRead] = useState(false)

    useEffect(() => {
        getNotifications()
    }, [])

    const getNotifications = (showLoaderFlag = true) => {
        setShowLoader(showLoaderFlag)
        get(`/api/notifications`)
            .then((response: any) => {
                setShowLoader(false)
                setNotifications(response.messages)
            })
            .catch((error) => {
                setShowLoader(false)
                showErrorToast(error.message)
            })
    }

    const markAllAsRead = () => {
        setMarkingAsRead(true)
        put(`/api/notifications/mark/all`, { status: 'Read' })
            .then((response: any) => {
                setMarkingAsRead(false)
                getNotifications(false)
            })
            .catch((error) => {
                setMarkingAsRead(false)
                showErrorToast(error.message)
            })
    }
    /*
     * Render
     */
    return (
        <div className="notifications">
            <div className="drawer-inner">
                <div className="drawer-content drawer-content-left">
                    <div className="drawer-content-header">
                        <div className="d-flex align-items-center">
                            <a
                                onClick={(e) => {
                                    e.preventDefault()
                                    onClose(true)
                                }}
                                href="#!"
                                className="pl-0 pr-1"
                            >
                                <i className="bx bx-arrow-back bx-sm"></i>
                            </a>
                            <h1>Notifications</h1>
                        </div>
                    </div>
                    <div className="drawer-content-body">
                        {!showLoader && notifications.length ? (
                            <div className="d-flex align-items-center mb-3">
                                <div className="text-muted w-50">
                                    <small>LATEST</small>
                                </div>
                                <div className="text-right w-50">
                                    {!markingAsRead && (
                                        <a
                                            href="#!"
                                            className="text-decoration-none"
                                            onClick={(e) => {
                                                e.preventDefault()
                                                markAllAsRead()
                                            }}
                                        >
                                            <small className="text-muted">
                                                Mark all as read
                                            </small>
                                        </a>
                                    )}
                                    {markingAsRead && <Loader />}
                                </div>
                            </div>
                        ) : (
                            <></>
                        )}
                        <div className="notification-items">
                            {!showLoader &&
                                notifications.map((notification: any) => (
                                    <div
                                        className={
                                            notification.users.status === 'Unread'
                                                ? `d-flex py-2 my-1 notification-item unread`
                                                : `d-flex py-2 my-1 notification-item`
                                        }
                                        key={notification._id}
                                    >
                                        <div className="avatar flex-shrink-0">
                                            <Avatar
                                                name={`${authenticationService.currentUserValue.firstName} ${authenticationService.currentUserValue.lastName}`}
                                                size="42"
                                                round={true}
                                                src={
                                                    authenticationService
                                                        .currentUserValue.avatar
                                                }
                                            />
                                        </div>
                                        <div className="flex-grow-1 pl-2">
                                            <div className="fw-medium mt-n1">
                                                {
                                                    authenticationService
                                                        .currentUserValue.firstName
                                                }{' '}
                                                {
                                                    authenticationService
                                                        .currentUserValue.lastName
                                                }
                                            </div>
                                            <div>{notification.message}</div>
                                            <small className="text-muted">
                                                <Moment fromNow>
                                                    {notification.createdAt}
                                                </Moment>
                                            </small>
                                        </div>
                                        <a
                                            href="#!"
                                            className="flex-shrink-0 ml-3 read-unread stretched-link"
                                            onClick={(e) => {
                                                e.preventDefault()
                                                if (
                                                    notification.navigate.type ===
                                                    'Relative'
                                                ) {
                                                    history.push(
                                                        notification.navigate.url
                                                    )
                                                } else {
                                                    window.location.href =
                                                        notification.navigate.url
                                                }
                                                onClose(true)
                                            }}
                                        ></a>
                                    </div>
                                ))}

                            {showLoader && <Loader className="py-2 text-center" />}
                        </div>
                    </div>
                    {!showLoader && !notifications.length && (
                        <div className="notifications-empty text-center my-5 py-4">
                            <img alt="sdas" src="/images/empty-notifications.png" />
                            <h4 className="text-muted my-5">
                                No notification right now
                            </h4>
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}
