import React from 'react'
import './Footer.scss'
export type FooterProps = any

export const Footer = (props: FooterProps) => {
    return (
        <footer className="p-4 p-sm-5">
            <div className="container text-center">
                <div className="text-center small mb-3 text-muted">
                    © 2021 99 Flows, All rights reserved.
                </div>
            </div>
        </footer>
    )
}
