import { ToastService } from '@angularminds/am-react-toaster'
/*
 * Custom methods for showing toasters
 */
export function showErrorToast(message: string) {
    ToastService.showToast('danger', message, 'Error!')
}

export function showSuccessToast(message: string) {
    ToastService.showToast('success', message, 'Success')
}

export function showWarningToast(message: string) {
    ToastService.showToast('warning', message, 'Warning!')
}

export function showInfoToast(message: string) {
    ToastService.showToast('info', message, 'Information')
}
