import { PUBLIC_VAPID } from './constants/keys'
import { post } from './http/httpMethods'

const convertedVapidKey = urlBase64ToUint8Array(PUBLIC_VAPID)

function urlBase64ToUint8Array(base64String) {
    const padding = '='.repeat((4 - (base64String.length % 4)) % 4)
    // eslint-disable-next-line
    const base64 = (base64String + padding).replace(/\-/g, '+').replace(/_/g, '/')

    const rawData = window.atob(base64)
    const outputArray = new Uint8Array(rawData.length)

    for (let i = 0; i < rawData.length; ++i) {
        outputArray[i] = rawData.charCodeAt(i)
    }
    return outputArray
}

function sendSubscription(subscription, isAppliedForSubscription = true) {
    const data = {
        notifications: [
            {
                userAgent: window.navigator.userAgent,
                isActive: true,
                payload: subscription,
            },
        ],
    }
    return post(
        isAppliedForSubscription
            ? `/api/notifications/push-subscription`
            : `/api/notifications/push-unsubscribe`,
        data
    ).then((response: any) => {
        return response
    })
}
//conditional render
let clicked = true

export function subscribeUser(isAppliedForSubscription = true) {
    if (clicked) {
        if ('serviceWorker' in navigator) {
            navigator.serviceWorker.ready
                .then(function (registration) {
                    if (!registration.pushManager) {
                        console.log('Push manager unavailable.')
                        return
                    }

                    registration.pushManager
                        .getSubscription()
                        .then(function (existedSubscription) {
                            if (existedSubscription === null) {
                                console.log(
                                    'No subscription detected, make a request.'
                                )
                                registration.pushManager
                                    .subscribe({
                                        applicationServerKey: convertedVapidKey,
                                        userVisibleOnly: true,
                                    })
                                    .then(function (newSubscription) {
                                        console.log(
                                            'New subscription added.',
                                            newSubscription
                                        )
                                        sendSubscription(
                                            newSubscription,
                                            isAppliedForSubscription
                                        )
                                    })
                                    .catch(function (e) {
                                        if (Notification.permission !== 'granted') {
                                            console.log(
                                                'Permission was not granted.'
                                            )
                                        } else {
                                            console.error(
                                                'An error ocurred during the subscription process.',
                                                e
                                            )
                                        }
                                    })
                            } else {
                                console.log('Existed subscription detected.')
                                sendSubscription(
                                    existedSubscription,
                                    isAppliedForSubscription
                                )
                            }
                        })
                })
                .catch(function (e) {
                    console.error(
                        'An error ocurred during Service Worker registration.',
                        e
                    )
                })
        }
    } else {
        console.log('Can not reachable to the service worker')
    }
}
