import React, { useState, useEffect } from 'react'
import { Input } from '../../components/inputs/text-input'
import history from '../../routes/history'
import { get } from '../../services/http/httpMethods'
import { showErrorToast } from '../../utils/toastUtil'
import './search-everything.scss'

export type SearchEverythingProps = {
    /**
     * On close
     */
    onClose?: any
}

export const SearchEverything = ({ onClose }: SearchEverythingProps) => {
    const [searchResults, setSearchResults] = useState([] as any)
    let isTermEmpty = false

    const search = (e) => {
        isTermEmpty = !e.target.value
        if (e.target.value === '') {
            return
        }
        get(`/api/search?term=${e.target.value}`)
            .then((response) => {
                setSearchResults(!isTermEmpty ? response.data : [])
            })
            .catch((error) => {
                showErrorToast(error.message)
            })
    }

    /*
     * Render
     */
    return (
        <>
            <div className="main-search">
                <div className="search-inner min-vh-100">
                    <a href="#!" className="hide-search">
                        <i className="bx bxs-chevron-left"></i>
                    </a>
                    <div className="search-header py-4">
                        <div className="search-input">
                            <Input
                                type="text"
                                name="name"
                                placeholder="Search everything..."
                                onChange={search}
                                autoComplete="off"
                            />
                        </div>

                        <ul className="nav">
                            <li className="nav-item">
                                <a
                                    className="nav-link text-muted disabled"
                                    href="#!"
                                >
                                    All
                                </a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link active" href="#!">
                                    Requests
                                </a>
                            </li>
                            <li className="nav-item">
                                <a
                                    className="nav-link text-muted disabled"
                                    href="#!"
                                >
                                    Flows
                                </a>
                            </li>
                            <li className="nav-item">
                                <a
                                    className="nav-link text-muted disabled"
                                    href="#!"
                                >
                                    Tasks
                                </a>
                            </li>
                            <li className="nav-item">
                                <a
                                    className="nav-link text-muted disabled"
                                    href="#!"
                                >
                                    People
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div className="search-body pb-4 pt-2">
                        {searchResults.count ? (
                            <div className="d-flex align-items-center pb-2">
                                <div className="flex-grow-1 text-muted">
                                    {searchResults.count} search results found
                                </div>
                                <a
                                    href="#!"
                                    onClick={(e) => {
                                        e.preventDefault()
                                        setSearchResults([])
                                    }}
                                >
                                    <small>CLEAR SEARCH</small>
                                </a>
                            </div>
                        ) : (
                            <></>
                        )}
                        <div
                            className={
                                searchResults.searchResults?.length > 5
                                    ? 'vertical-scroll'
                                    : ''
                            }
                        >
                            {searchResults.searchResults?.map((result, index) => (
                                <div className="mt-3 search-display" key={`${index}-sr`}>
                                    <h4 className="mb-1">
                                        <a
                                            href="#!"
                                            style={{ color: '#fff' }}
                                            onClick={(e) => {
                                                e.preventDefault()
                                                history.push(result.relativeUrl)
                                                onClose()
                                            }}
                                        >
                                            {result.title}
                                        </a>
                                    </h4>
                                    <div className="text-muted">
                                        {result.description}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                    {/* <div className="search-footer">
                        <div className="saerch-pagination">
                            <a href="#!" className="active">
                                1
                            </a>
                            <a href="#!">2</a>
                            <a href="#!">3</a>
                            <a href="#!">4</a>
                        </div>
                    </div> */}
                </div>
            </div>
            <div className="hide-roller vh-100 bg-light">
                <button
                    type="button"
                    className="btn btn-light btn-chevron btn-chevron-right"
                    onClick={(e) => {
                        onClose(true)
                    }}
                >
                    <i className="bx bxs-chevron-left"></i>
                </button>
            </div>
        </>
    )
}
